import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import StorageKey from '../shared/StorageKey'

export default function OAuthCallbackScreen(): React.ReactElement {
    const query = new URLSearchParams(useLocation().search)
    useEffect(() => {
        const code = query.get('code')
        if (code) {
            localStorage.setItem(StorageKey.AUTH_CODE, code)
        }
        window.close()
    })
    return <div>{query.get('code')}</div>
}
